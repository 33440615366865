import './style.css'
import * as dat from 'lil-gui'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import glowVertexShader from './vertex.glsl'
import glowFragmentShader from './fragment.glsl'
import { DirectionalLight, Vector3 } from 'three'
import gsap from 'gsap'




/**
 * Spector JS
 */
// const SPECTOR = require('spectorjs')
// const spector = new SPECTOR.Spector()
// spector.displayUI()

/**
 * Base
 */
//Debug
// const gui = new dat.GUI({
//     width: 400
// })

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


/**
 * Overlay
 */

 window.addEventListener("load", function() {
    var loadingScreen = document.getElementById("loading-screen");
    loadingScreen.style.opacity = "0";
    setTimeout(function() {
      loadingScreen.style.display = "none";
    }, 2000);
  });


/**
 * Loaders
 */

// Load Manager
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        gsap.to()
    },

    // Progress
    () =>
    {
        console.log('progress')
    }
)

// Texture loader
const textureLoader = new THREE.TextureLoader(loadingManager)

// Draco loader
const dracoLoader = new DRACOLoader(loadingManager)
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)


/**
 * Shaders
 */
 function changeMaterialToFakeVolume(objectToBeChanged){

    const changedMaterial = new THREE.MeshBasicMaterial({
        side: THREE.FrontSide,
        blending: THREE.AdditiveBlending,
        transparent: true,
        depthWrite: false,
    })

    changedMaterial.onBeforeCompile = function(shader) {
        shader.uniforms.c = { type: "f", value: 1 };
        shader.uniforms.p = { type: "f", value: 1.94 };
        shader.uniforms.glowColor = { type: "c", value: new THREE.Color('cyan')};
        shader.uniforms.viewVector = { type: "v3", value: camera.position };
        shader.uniforms.op = { type: "f", value: 0.57 };
        shader.vertexShader = glowVertexShader
        shader.fragmentShader = glowFragmentShader
        changedMaterial.userData.shader = shader;
        // gui.add(shader.uniforms["c"], 'value').min(-1).max(1).step(0.01)
        // gui.add(shader.uniforms["p"], 'value').min(-1).max(6).step(0.01)
        // gui.add(shader.uniforms["op"], 'value').min(-1).max(1).step(0.01)
    };
    objectToBeChanged.material = changedMaterial;
    objectToBeChanged.needsUpdate = true;
}

/**
 * Textures
 */
const bakedTexture = textureLoader.load('Baked10.jpg')
bakedTexture.flipY = false
bakedTexture.encoding = THREE.sRGBEncoding


const monitorTexture = textureLoader.load('monitorAboutMe2.png')

// hologram slides
const hologramimage1 = textureLoader.load('HoloSlideRounded1.png')
const hologramimage2 = textureLoader.load('HoloSlideRounded2.png')
const hologramimage3 = textureLoader.load('HoloSlideRounded3.png')
const hologramimage4 = textureLoader.load('HoloSlideRounded4.png')
const hologramTexture1 = new THREE.MeshBasicMaterial({ map: hologramimage1, transparent: true })
const hologramTexture2 = new THREE.MeshBasicMaterial({ map: hologramimage2, transparent: true })
const hologramTexture3 = new THREE.MeshBasicMaterial({ map: hologramimage3, transparent: true })
const hologramTexture4 = new THREE.MeshBasicMaterial({ map: hologramimage4, transparent: true })
const hologramTextureSlideArr = [hologramTexture1, hologramTexture2, hologramTexture3, hologramTexture4]

// app images
const appBRimage = textureLoader.load('instalogorotated.png')
const appTLimage = textureLoader.load('linkedinlogorotated.png')
const appTRimage = textureLoader.load('maillogorotated.png')
const appBLimage = textureLoader.load('twitterlogorotated.png')
const phoneBackgroundimage = textureLoader.load('iphonebackground1.jpg')
const phoneMaterial = new THREE.MeshBasicMaterial({ map: phoneBackgroundimage})
const appBRMaterial = new THREE.MeshBasicMaterial({ map: appBRimage, transparent: true })
const appTLMaterial = new THREE.MeshBasicMaterial({ map: appTLimage, transparent: true })
const appTRMaterial = new THREE.MeshBasicMaterial({ map: appTRimage, transparent: true })
const appBLMaterial = new THREE.MeshBasicMaterial({ map: appBLimage, transparent: true })


/**
 * Materials special
 */
// Baked material
const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })


// neon light material
const neonLightMaterial = new THREE.MeshBasicMaterial({ color: 0x19d1ff })



/**
 * Models
 */

gltfLoader.load(
    'baked10geometry2.glb',
    (gltf) =>
    {
        gltf.scene.traverse((child) => 
        {
            child.material = bakedMaterial
        })

        const neonNolanMesh = gltf.scene.children.find((child) =>
        {
            return child.name === 'NeonNolan'
        })
        const hologramCylinder = gltf.scene.children.find((child) =>
        {
            return child.name === 'holotablehologram'
        })
        
        neonNolanMesh.material = neonLightMaterial
        gltf.scene.rotation.y = 0
        changeMaterialToFakeVolume(hologramCylinder)

        scene.add(gltf.scene)
    }
)

gltfLoader.load(
    '/low_poly_raygun/raygun.gltf',
    (gltf) =>
    {
        gltf.scene.scale.set(.05, .05, .05)
        gltf.scene.position.set(2.226, 2.66, -2.0)
        gltf.scene.rotation.y = 0
        gltf.scene.rotation.x = -0.395
        scene.add(gltf.scene)

    }
)

gltfLoader.load(
    '/master_chief_mark_vi_helmet/scene.gltf',
    (gltf) =>
    {
        gltf.scene.scale.set(.25, .25, .25)
        gltf.scene.position.set(1.639, 2.708, -.786)
        gltf.scene.rotation.x = -.647
        gltf.scene.rotation.y = -1.195
        gltf.scene.rotation.z = -.647
        scene.add(gltf.scene)

        // gui.add(gltf.scene.position, 'x').min(-3).max(3).step(0.001).name('raygun x')
        // gui.add(gltf.scene.position, 'y').min(-3).max(3).step(0.001).name('raygun y')
        // gui.add(gltf.scene.position, 'z').min(-3).max(3).step(0.001).name('raygun z')
        // gui.add(gltf.scene.rotation, 'x').min(-7).max(7).step(0.001).name('raygun rotation x')
        // gui.add(gltf.scene.rotation, 'y').min(-7).max(7).step(0.001).name('raygun rotation y')
        // gui.add(gltf.scene.rotation, 'z').min(-7).max(7).step(0.001).name('raygun rotation z')


    }
)


// Monitor
const monitorGeometry = new THREE.PlaneGeometry(16, 9, 1, 1)
const monitorMaterial = new THREE.MeshBasicMaterial( { map: monitorTexture } )
const monitorPlane = new THREE.Mesh(monitorGeometry, monitorMaterial)
monitorPlane.rotation.y = Math.PI
monitorPlane.scale.set(.107, .107, .107)
monitorPlane.position.set(-1.18, 2.41, 2.023)
scene.add(monitorPlane)
// gui.add(monitorPlane.rotation, 'y').min(-3).max(3).step(0.001).name('monitor rotation y')
// gui.add(monitorPlane.position, 'x').min(-3).max(3).step(0.001).name('monitor position x')
// gui.add(monitorPlane.position, 'y').min(-3).max(3).step(0.001).name('monitor position y')
// gui.add(monitorPlane.position, 'z').min(-3).max(3).step(0.001).name('monitor position z')


// Phone
const phoneGeometry = new THREE.PlaneGeometry(2.6, 5.7, 1, 1)
const phonePlane = new THREE.Mesh(phoneGeometry, phoneMaterial)
phonePlane.rotation.x = Math.PI * 0.5
phonePlane.rotation.y = Math.PI
phonePlane.rotation.z = Math.PI

phonePlane.scale.set(.05, .05, .05)
phonePlane.position.set(1.321, 0.843, -2.224)
scene.add(phonePlane)
// gui.add(phonePlane.rotation, 'x').min(-7).max(7).step(0.001).name('phone rotation x')
// gui.add(phonePlane.rotation, 'y').min(-7).max(7).step(0.001).name('phone rotation y')
// gui.add(phonePlane.rotation, 'y').min(-7).max(7).step(0.001).name('phone rotation z')
// gui.add(phonePlane.position, 'x').min(-3).max(3).step(0.001).name('phone position x')
// gui.add(phonePlane.position, 'y').min(-3).max(3).step(0.001).name('phone position y')
// gui.add(phonePlane.position, 'z').min(-3).max(3).step(0.001).name('phone position z')

// Table
const holoDisplayGeometry = new THREE.PlaneBufferGeometry(3, 3, 1, 1)
const holoDisplay = new THREE.Mesh(holoDisplayGeometry, hologramTexture1)
holoDisplay.rotation.x = Math.PI * .5
holoDisplay.rotation.y = Math.PI * 3
holoDisplay.rotation.c = Math.PI * -1

holoDisplay.scale.set(.58, .58, .58)
holoDisplay.position.set(-1.06, 1.12, -1.6)
let slidecount = 0
scene.add(holoDisplay)

// gui.add(holoDisplay.rotation, 'x').min(-7).max(7).step(0.001).name('holoDisplay rotation x')
// gui.add(holoDisplay.rotation, 'y').min(-7).max(7).step(0.001).name('holoDisplay rotation y')
// gui.add(holoDisplay.rotation, 'y').min(-7).max(7).step(0.001).name('holoDisplay rotation z')
// gui.add(holoDisplay.position, 'x').min(-3).max(3).step(0.001).name('holoDisplay position x')
// gui.add(holoDisplay.position, 'y').min(-3).max(3).step(0.001).name('holoDisplay position y')
// gui.add(holoDisplay.position, 'z').min(-3).max(3).step(0.001).name('holoDisplay position z')


// Previous slide
const previousSlideGeometry = new THREE.PlaneBufferGeometry(1, 2, 1, 1)
const previousSlideMaterial = new THREE.MeshBasicMaterial( { color: 0xffffff, transparent: true, opacity: 0 } )
const previousSlideMesh = new THREE.Mesh(previousSlideGeometry, previousSlideMaterial)
previousSlideMesh.rotation.x = Math.PI * .5
previousSlideMesh.rotation.y = Math.PI * 3
previousSlideMesh.rotation.c = Math.PI * -1

previousSlideMesh.scale.set(.65, .65, .65)
previousSlideMesh.position.set(-0.59, 1.1, -1.6)
scene.add(previousSlideMesh)




// Next slide
const nextSlideGeometry = new THREE.PlaneBufferGeometry(1, 2, 1, 1)
const nextSlideMaterial = new THREE.MeshBasicMaterial( { color: 0xffffff, transparent: true, opacity: 0  } )
const nextSlideMesh = new THREE.Mesh(nextSlideGeometry, nextSlideMaterial)
nextSlideMesh.rotation.x = Math.PI * .5
nextSlideMesh.rotation.y = Math.PI * 3
nextSlideMesh.rotation.c = Math.PI * -1

nextSlideMesh.scale.set(.65, .65, .65)
nextSlideMesh.position.set(-1.529, 1.1, -1.6)
scene.add(nextSlideMesh)

// App TL
const appTLGeometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1)
var appTLMesh = new THREE.Mesh(appTLGeometry, appTLMaterial)
appTLMesh.rotation.x = Math.PI * .5
appTLMesh.rotation.y = Math.PI * 3
appTLMesh.rotation.c = Math.PI * -1
appTLMesh.scale.set(.05, .05, .05)
appTLMesh.position.set(1.287, 0.844, -2.329)
appTLMesh.userData = { URL: 'https://www.linkedin.com/in/nolan-rossi/'}
scene.add(appTLMesh)

// App TR
const appTRGeometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1)
const appTRMesh = new THREE.Mesh(appTRGeometry, appTRMaterial)
appTRMesh.rotation.x = Math.PI * .5
appTRMesh.rotation.y = Math.PI * 3
appTRMesh.rotation.c = Math.PI * -1
appTRMesh.scale.set(.05, .05, .05)
appTRMesh.position.set(1.354, 0.844, -2.329)
appTRMesh.userData = { URL: 'mailto:nolanrossi100@gmail.com'}
scene.add(appTRMesh)

// App BL
const appBLGeometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1)
const appBLMesh = new THREE.Mesh(appBLGeometry, appBLMaterial)
appBLMesh.rotation.x = Math.PI * .5
appBLMesh.rotation.y = Math.PI * 3
appBLMesh.rotation.c = Math.PI * -1
appBLMesh.scale.set(.05, .05, .05)
appBLMesh.position.set(1.287, 0.844, -2.262)
appBLMesh.userData = { URL: 'http://twitter.com/NolanRossi100'}
scene.add(appBLMesh)

// App BR
const appBRGeometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1)
const appBRMesh = new THREE.Mesh(appBRGeometry, appBRMaterial)
appBRMesh.rotation.x = Math.PI * .5
appBRMesh.rotation.y = Math.PI * 3
appBRMesh.rotation.c = Math.PI * -1
appBRMesh.scale.set(.05, .05, .05)
appBRMesh.position.set(1.354, 0.844, -2.262)
appBRMesh.userData = { URL: 'http://instagram.com/nolan.rossi/'}
scene.add(appBRMesh)


// foundation
const foundationGeometry = new THREE.BoxBufferGeometry(5.65, 40, 5.65, 1)
const foundationMaterial = new THREE.MeshStandardMaterial()
foundationMaterial.roughness = .7
const foundationMesh = new THREE.Mesh(foundationGeometry, foundationMaterial)

foundationMesh.position.set(-.185, -20, -.185)

scene.add(foundationMesh)

// foundation top
const foundationTopGeometry = new THREE.PlaneBufferGeometry(5.65, 5.65, 1, 1)
const foundationTopMaterial = new THREE.MeshBasicMaterial( {color: 'black'} )
const foundationTop = new THREE.Mesh(foundationTopGeometry, foundationTopMaterial)
foundationTop.rotation.x = Math.PI * .5
foundationTop.rotation.y = Math.PI * 3
foundationTop.rotation.c = Math.PI * -1
foundationTop.position.set(0, 0.1, 0)
scene.add(foundationTop)

/**
 * Lights
 */

const pointLight1 = new THREE.PointLight(0xffffff, 5)
pointLight1.position.set(-5, -2.94, -3.461)
scene.add(pointLight1)

const pointLight2 = new THREE.PointLight(0xffffff, 4.125)
pointLight2.position.set(2.702, 5, 5.101)
scene.add(pointLight2)

const pointLight3 = new THREE.PointLight(0xffffff, .3)
pointLight3.position.set(2.389, 4.684, -2.826)
scene.add(pointLight3)

// gui.add(pointLight2.position, 'x').min(-8).max(8).step(0.001).name('x')
// gui.add(pointLight2.position, 'y').min(-8).max(8).step(0.001).name('y')
// gui.add(pointLight2.position, 'z').min(-8).max(8).step(0.001).name('z')






/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * mouse
 */
const mouse = new THREE.Vector2()

const touchHandler = function(e)
{
    if(e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel'){
        let touch = e.changedTouches[0] || e.touches[0]
        mouse.x = touch.pageX / sizes.width * 2 - 1
        mouse.y = -(touch.pageY / sizes.height) * 2 + 1
    } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover'|| e.type=='mouseout' || e.type=='mouseenter' || e.type=='mouseleave') {
        mouse.x = e.clientX / sizes.width * 2 - 1
        mouse.y = -(e.clientY / sizes.height) * 2 + 1
    }
}

window.addEventListener('touchstart', touchHandler, false)
window.addEventListener('touchmove', touchHandler, false)
window.addEventListener('touchend', touchHandler, false)
window.addEventListener('touchcancel', touchHandler, false)

window.addEventListener('mousedown', touchHandler, false)
window.addEventListener('mouseup', touchHandler, false)
window.addEventListener('mousemove', touchHandler, false)
window.addEventListener('mouseover', touchHandler, false)
window.addEventListener('mouseout', touchHandler, false)
window.addEventListener('mouseenter', touchHandler, false)
window.addEventListener('mouseleave', touchHandler, false)

// mouse.x = event.clientX / sizes.width * 2 - 1
// mouse.y = -(event.clientY / sizes.height) * 2 + 1
/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.x = -7.761
camera.position.y = 6.363
camera.position.z = -8.805
scene.add(camera)
// gui.add(camera.position, 'x').min(-20).max(20).step(0.001).name('camera position x')
// gui.add(camera.position, 'y').min(-20).max(20).step(0.001).name('camera position y')
// gui.add(camera.position, 'z').min(-20).max(20).step(0.001).name('camera position z')




// Camera Controls
const controls = new OrbitControls(camera, canvas)
controls.target = new THREE.Vector3(0, 1, 0)
controls.enablePan = false
controls.enableDamping = true
controls.minPolarAngle = 0
controls.maxPolarAngle = Math.PI * 0.5  
controls.minAzimuthAngle = -Math.PI - 0.2
controls.maxAzimuthAngle = -Math.PI * 0.5 +  0.2
controls.minDistance = 4.5
controls.maxDistance = 13.8





/**
 *  *****INTERACTION CONTROLS PT1*****
 */ 
var raycaster = new THREE.Raycaster()
let holobool
let monitorbool
let phonebool
let previousslidebool
let nextslidebool
let appTLbool
let appTRbool
let appBLbool
let appBRbool


// gui.add(controls, 'minDistance').min(-20).max(20).step(0.001).name('minDistance')
// gui.add(controls, 'maxDistance').min(-20).max(20).step(0.001).name('maxDistance')

function returnCameraToOrigin() 
{
    controls.target = new THREE.Vector3(0, 1, 0)
    gsap.to(camera.position,
        {
            x: -7.761,
            y: 6.363,
            z: -8.805,
            duration: 1
        })
        controls.minPolarAngle = 0
        controls.maxPolarAngle = Math.PI * 0.5
        controls.minAzimuthAngle = -Math.PI - 0.2
        controls.maxAzimuthAngle = -Math.PI * 0.5 + 0.2
        controls.minDistance = 4.5
        controls.maxDistance = 13.8
}

function cameraToMonitor()
{
    controls.target = new THREE.Vector3(-1.18, 2.41, 2.028)
    gsap.to(camera.position,
        {
            x: -1.068,
            y: 3.105,
            z: -0.285,
            duration: 1,
            
        })
        controls.minPolarAngle = Math.PI * 0.5 -.2
        controls.maxPolarAngle = Math.PI * 0.5 -.2
        controls.maxAzimuthAngle = -Math.PI
        controls.minAzimuthAngle = -Math.PI
        controls.minDistance = 3.6
        controls.maxDistance = 7.8
}

function cameraToMonitor2()
{
    controls.target = new THREE.Vector3(-1.18, 2.41, 2.028)
    gsap.to(camera.position,
        {
            x: -1.068,
            y: 3.105,
            z: -0.285,
            duration: 1,
            
        })
        controls.minPolarAngle = Math.PI * 0.5 -.2
        controls.maxPolarAngle = Math.PI * 0.5 -.2
        controls.maxAzimuthAngle = -Math.PI
        controls.minAzimuthAngle = -Math.PI
        controls.minDistance = 2.6
        controls.maxDistance = 6.8
}

function cameraToHologram()
{
    controls.target = new THREE.Vector3(-1.06, 1.12, -1.6)
    gsap.to(camera.position,
        {
            x: -1.068,
            y: 3.626,
            z: -2.893,
            duration: 1
        })
        controls.minPolarAngle = 0.236
        controls.maxPolarAngle = 0.236
        controls.maxAzimuthAngle = -Math.PI
        controls.minAzimuthAngle = -Math.PI
        controls.minDistance = 4
        controls.maxDistance = 7.4
}

function cameraToHologram2()
{
    controls.target = new THREE.Vector3(-1.06, 1.12, -1.6)
    gsap.to(camera.position,
        {
            x: -1.068,
            y: 3.626,
            z: -2.893,
            duration: 1
        })
        controls.minPolarAngle = 0.236
        controls.maxPolarAngle = 0.236
        controls.maxAzimuthAngle = -Math.PI
        controls.minAzimuthAngle = -Math.PI
        controls.minDistance = 2.8
        controls.maxDistance = 7.4
}

function cameraToPhone()
{
    controls.target = new THREE.Vector3(1.321, 0.843, -2.224)
    gsap.to(camera.position,
        {
            x: 1.32, 
            y: 1.34, 
            z: -2.111,
            duration: 1
        })
    controls.minPolarAngle = 0
    controls.maxPolarAngle = 0
    controls.minAzimuthAngle = 0
    controls.maxAzimuthAngle = 0
    controls.minDistance = .5
    controls.maxDistance = 3
}

document.getElementById('home').onclick = () =>
{
    returnCameraToOrigin()
}
document.getElementById('aboutme').onclick = () =>
{
    cameraToMonitor()
}
document.getElementById('projects').onclick = () =>
{
    cameraToHologram()
}
document.getElementById('contact').onclick = () =>
{
    cameraToPhone()
}


window.addEventListener('keypress', (event) =>
{
    returnCameraToOrigin() 
})

window.addEventListener('keydown', (event) =>
{
    if (event.key == 'Escape')
    {
        returnCameraToOrigin() 
    }
})

window.addEventListener('contextmenu', (event) =>
{
    event.preventDefault()
    returnCameraToOrigin() 
    return false
})

window.addEventListener('mousedown', (event) =>
{
    if (holobool && !controls.target.equals(new THREE.Vector3(-1.06, 1.12, -1.6)))
    {
        console.log()
        cameraToHologram2()
    }
    if (monitorbool)
    {
        console.log('clicked monitor')
        cameraToMonitor2()
    }
    if (phonebool && !controls.target.equals(new THREE.Vector3(1.321, 0.843, -2.224)))
    {
        console.log('clicked phone')
        cameraToPhone()
    }
    if (appTLbool)
    {
        console.log('clicked appTL')
        window.open(appTLMesh.userData.URL)
    }
    if (appTRbool)
    {
        console.log('clicked appTR')
        window.open(appTRMesh.userData.URL)
    }
    if (appBLbool)
    {
        console.log('clicked appBL')
        window.open(appBLMesh.userData.URL)
    }
    if (appBRbool)
    {
        console.log('clicked appBR')
        window.open(appBRMesh.userData.URL)
    }
    if (previousslidebool)
    {
        console.log('clicked previous slide')
        if (slidecount>0)
        {
            slidecount--
        }
        
        holoDisplay.material = hologramTextureSlideArr[slidecount]
    }
    if (nextslidebool)
    {
        console.log('clicked next slide')
        if (slidecount<3)
        {
            slidecount++
        }
        holoDisplay.material = hologramTextureSlideArr[slidecount]
    }
}, false)

window.addEventListener('touchstart', (event) =>
{
    if (holobool && !controls.target.equals(new THREE.Vector3(-1.06, 1.12, -1.6)))
    {
        cameraToHologram()
        console.log('clicked hologram')
    }
    if (monitorbool)
    {
        console.log('clicked monitor')
        cameraToMonitor()
        
    }
    if (phonebool && !controls.target.equals(new THREE.Vector3(1.321, 0.843, -2.224)))
    {
        console.log('clicked phone')
        cameraToPhone()
    }
    if (appTLbool)
    {
        console.log('clicked appTL')
        window.open(appTLMesh.userData.URL)
    }
    if (appTRbool)
    {
        console.log('clicked appTR')
        window.open(appTRMesh.userData.URL)
    }
    if (appBLbool)
    {
        console.log('clicked appBL')
        window.open(appBLMesh.userData.URL)
    }
    if (appBRbool)
    {
        console.log('clicked appBR')
        window.open(appBRMesh.userData.URL)
    }
    if (previousslidebool)
    {
        console.log('clicked previous slide')
        if (slidecount>0)
        {
            slidecount--
        }
        
        holoDisplay.material = hologramTextureSlideArr[slidecount]
    }
    if (nextslidebool)
    {
        console.log('clicked next slide')
        if (slidecount<3)
        {
            slidecount++
        }
        holoDisplay.material = hologramTextureSlideArr[slidecount]
    }
}, false)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding

/**
 * Animate
 */
const clock = new THREE.Clock()



const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()
    /**
     *  *****INTERACTION CONTROLS PT2*****
    */ 
    raycaster.setFromCamera(mouse, camera)
    let intersectHolo = raycaster.intersectObjects([holoDisplay])
    let intersectmonitor = raycaster.intersectObjects([monitorPlane])
    let intersectphone = raycaster.intersectObjects([phonePlane])
    let intersectpreviousslide = raycaster.intersectObjects([previousSlideMesh])
    let intersectnextslide = raycaster.intersectObjects([nextSlideMesh])
    let intersectappTL = raycaster.intersectObjects([appTLMesh])
    let intersectappTR = raycaster.intersectObjects([appTRMesh])
    let intersectappBL = raycaster.intersectObjects([appBLMesh])
    let intersectappBR = raycaster.intersectObjects([appBRMesh])

    holobool = false
    for (const intersect of intersectHolo) 
    {
        holobool = true
    }
    monitorbool = false
    for (const intersect of intersectmonitor) 
    {
        monitorbool = true
    }
    phonebool = false
    for (const intersect of intersectphone) 
    {
        phonebool = true
    }
    appTLbool = false
    for (const intersect of intersectappTL) 
    {
        appTLbool = true
    }
    appTRbool = false
    for (const intersect of intersectappTR) 
    {
        appTRbool = true
    }
    appBLbool = false
    for (const intersect of intersectappBL) 
    {
        appBLbool = true
    }
    appBRbool = false
    for (const intersect of intersectappBR) 
    {
        appBRbool = true
    }
    previousslidebool = false
    for (const intersect of intersectpreviousslide) 
    {
        previousslidebool = true
    }
    nextslidebool = false
    for (const intersect of intersectnextslide) 
    {
        nextslidebool = true
    }

    


    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)


}

tick()